<template>
  <CurrencyValue
    :currency="currencyIso"
    v-if="Number.isFinite(value) && currencyIso && !isValuationTypePercentage"
    :decimal="decimal"
  >
    {{ value }}
  </CurrencyValue>
  <PercentageValue v-else-if="isValuationTypePercentage" :value="value" />
  <strong class="unit" v-else-if="Number.isFinite(value)">{{ commaNumber(value, "'") }}</strong>
  <div class="currency-value not-available" v-else-if="!loading">N/A</div>
  <Date
    v-if="showDate && date"
    dateColor="var(--secondary-color-light-alpha)"
    size="small"
    :inline="false"
  >
    {{ date }}
  </Date>
  <Skeleton v-if="loading" width="8ch" height="1.25em" />
</template>

<script>
import Skeleton from "primevue/skeleton";
import CurrencyValue from "@/components/elements/CurrencyValue";
import Date from "@/components/elements/Date";
import commaNumber from "comma-number";
import { ValuationTypeEnum } from "../product/Types";
import PercentageValue from "@/components/elements/PercentageValue";

export default {
  name: "Valuation",
  components: {
    CurrencyValue,
    Date,
    Skeleton,
    PercentageValue,
  },
  props: {
    value: Number,
    date: String,
    currencyIso: String,
    showDate: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    decimal: {
      type: Number,
      default: 2,
    },
    valuationTypeId: Number,
  },
  methods: {
    commaNumber,
  },
  computed: {
    isValuationTypePercentage() {
      return this.valuationTypeId == ValuationTypeEnum.Percentage;
    },
  },
};
</script>

<style scoped lang="scss">
.not-available {
  min-width: 7rem;
  line-height: 1;
}
.unit {
  line-height: 1;
}
</style>
